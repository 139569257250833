import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { AUTH_TOKEN, ORDER_STORAGE_KEY } from '../constants/config';
import { UserData } from '../models/models';
import { LocalstorageService } from './localstorage.service';
import { DOCUMENT } from '@angular/common';

interface AccessToken {
  access_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private http = inject(HttpClient);
  private apiService = inject(ApiService);
  private dialogRef = inject(MatDialog);
  private localStorage = inject(LocalstorageService);
  private router = inject(Router);
  public isLogedIn = signal(false);
  public userValue = signal<UserData | null>(null);
  private user: any;
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.isLogedIn.set(this.isAuthenticated());
  }

  // LOGIN
  login(value: any): Observable<any> {
    return this.http
      .post<AccessToken>(`${environment.APP_ENDPOINT}/api/auth/login`, value)
      .pipe(
        tap((response: AccessToken) => {
          this.setSession(response.access_token);
          this.isLogedIn.set(true);
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err?.error);
        })
      );
  }

  // LOGOUT
  logout(): Observable<any> {
    return this.http.get(`${environment.APP_ENDPOINT}/api/auth/logout`).pipe(
      tap(() => {
        this.clearSession();
      }),
      catchError(this.apiService.handleError())
    );
  }

  // REGISTER USER
  registration(data: any): Observable<any> {
    return this.http
      .post<AccessToken>(`${environment.APP_ENDPOINT}/api/auth/signUp`, data)
      .pipe(
        tap((response: AccessToken) => {
          this.setSession(response.access_token);
          this.isLogedIn.set(true);
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err?.error);
        })
      );
  }

  // PRE REGISTER USER
  preRegistration(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/auth/preRegister`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // VERIFY PHONE REQUEST
  verifyPhone(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/auth/sendCheckPhoneRegister`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // VERIFY EMAIL REQUEST
  verifyEmail(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/auth/recoverPasswordRequest`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // CONFIRM PHONE CODE
  verifyCodePhone(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/auth/checkPhoneRegister`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // CONFIRM EMAIL CODE
  verifyCodeEmail(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/auth/checkEmailRegister`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // UPLOAD SELFIE PHOTO
  verifyPhotoID(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/uploadPhoto`, { data })
      .pipe(catchError(this.apiService.handleError()));
  }

  // UPLOAD USER DOCUMENT
  uploadUserDocument(data: any): Observable<any> {
    const httpOptions: any = {
      reportProgress: true,
      observe: 'events'
    };
    return this.http
      .post(
        `${environment.APP_ENDPOINT}/api/user/uploadFileId`,
        data,
        httpOptions
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  //CREATE PASSWORD REQUEST
  resetPassword(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/auth/recoverPassword`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  //CREATE PASSWORD REQUEST WITHOUT AUTH
  registrationPassordPublic(value: any): Observable<any> {
    return this.http
      .post<AccessToken>(
        `${environment.APP_ENDPOINT}/api/auth/passwordRegisterByToken`,
        value
      )
      .pipe(
        tap((response: AccessToken) => {
          this.setSession(response.access_token);
          this.isLogedIn.set(true);
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err?.error);
        })
      );
  }

  // CONFIG
  getConfig() {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/menu/getConfigs`)
      .pipe(catchError(this.apiService.handleError()));
  }

  //SAVE ADDRESS
  saveAddress(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/auth/addAddressRegister`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  //SAVE ADDRESS REGISTRATION
  saveAddressRegister(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/auth/signUpAddress`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  //SAVE ADDRESS FOR USER BY SALES
  saveAddressBySales(data: any): Observable<any> {
    return this.http
      .post(
        `${environment.APP_ENDPOINT}/api/auth/addAddressRegisterByToken`,
        data
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  //GET USER DATA
  userData(): Observable<any> {
    return this.http.get(`${environment.APP_ENDPOINT}/api/user/self`).pipe(
      tap((response: any) => {
        this.user = response;
        this.userValue.set(response);
      }),
      catchError(this.apiService.handleError())
    );
  }

  //GET USER DATA
  getQuizByType(type: string): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/getQuiz`, { type })
      .pipe(catchError(this.apiService.handleError()));
  }

  //CHECK IS USER CREATED
  userExist(id: any): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/auth/checkUserStatus/${id}`)
      .pipe(catchError(this.apiService.handleError()));
  }

  //LOCAL STORAGE FUNC
  setSession(token: string): void {
    this.localStorage.setItem(AUTH_TOKEN, token);
  }

  clearSession(): void {
    this.localStorage.removeItem(AUTH_TOKEN);
    this.localStorage.removeItem(ORDER_STORAGE_KEY);
    this.isLogedIn.set(false);
    this.userValue.set(null);
    this.dialogRef.closeAll();
    this.router.navigate(['/']);
  }

  getUser() {
    return this.user;
  }

  getToken(): string {
    return this.localStorage.getItem(AUTH_TOKEN) || '';
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  getState() {
    const state = JSON.parse(this.localStorage.getItem('state') || '{}');
    return state?.id ? state.id : null;
  }

  pageIndexLoader() {
    const body = this.document.getElementsByTagName('body')[0];
    body.classList.add('hide-legitscript');
    body.classList.add('hide-loader');
  }

  hidePageElements() {
    const body = this.document.getElementsByTagName('body')[0];
    body.classList.remove('hide-legitscript');
  }
}
